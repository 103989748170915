
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CacheService } from '@core/cache/cache.service';
import { Employee } from '@core/employee/employee.types';
import { MerchantService } from '@core/merchant/merchant.service';
import { Merchant } from '@core/merchant/merchant.types';
import { OrderService } from '@core/order/order.service';
import { Order } from '@core/order/order.types';
import { DEFAULT_HTTP_OPTIONS_5_MINUTES, DEFAULT_HTTP_OPTIONS_NO_EXPIRE, HttpOptions } from '@core/utils/http-options.types';
import { environment } from '@env/environment';
import moment from 'moment-timezone';
import { BehaviorSubject, catchError, Observable, of, tap } from 'rxjs';


/**
 * Service for managing calendar-related functionality.
 */
@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  /**
   * The merchant associated with the calendar.
   */
  private _merchant: Merchant;

  /**
   * The availability of employees.
   */
  private _availability: BehaviorSubject<any> = new BehaviorSubject([]);

  /**
   * The events of employees.
   */
  private _events: BehaviorSubject<any> = new BehaviorSubject([]);

  /**
   * The cache namespace.
   */
  private _cacheNamespace: string = 'calendar';

  /**
   * Constructor for the CalendarService class.
   * @param _merchantService The merchant service.
   * @param _orderService The order service.
   * @param _httpClient The HTTP client.
   * @param _cacheService The cache service.
   */
  constructor(
    private _merchantService: MerchantService,
    private _orderService: OrderService,
    private _httpClient: HttpClient,
    private _cacheService: CacheService
  ) {
    // Get the merchant
    this._merchantService.merchant$
      .subscribe((merchant: Merchant) => {
        const reload = this._merchant?._id.toString() !== merchant?._id.toString();
        this._merchant = merchant;
        if (reload) {
          this._availability.next([]);
          this._events.next([]);
        }
      });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Returns an observable of the availability data.
   * @returns An observable of the availability data.
   */
  get availability$(): Observable<any> {
    return this._availability.asObservable();
  }

  /**
   * Returns an observable of the events.
   * @returns An observable of the events.
   */
  get events$(): Observable<any> {
    return this._events.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------


  /**
   * Returns the available time slots for the given employee IDs, start date, end date, and duration.
   * @param employeeIds An array of employee IDs.
   * @param startDate The start date of the availability search.
   * @param endDate The end date of the availability search.
   * @param duration The duration of the time slots in minutes.
   * @param options - The options related to cache and propagation.
   * @returns The available time slots.
   */
  getAvailableTimeSlots(
    employeeIds: string[],
    startDate: Date,
    endDate: Date,
    duration: number,
    options: HttpOptions=DEFAULT_HTTP_OPTIONS_5_MINUTES
  ): Observable<any> {
    if (!this._merchant) {
      console.log('Error: No merchant.');
      return of([]);
    }
    // If the time slots are already loaded, return them
    const cacheParams: any = { 
      merchantId: this._merchant._id,
      employeeIds,
      startDate,
      endDate,
      duration
    };
    if (options.cache && this._cacheService.has(this._cacheNamespace, 'availability', cacheParams)) {
      const availability: any = this._cacheService.get(this._cacheNamespace, 'availability', cacheParams);
      if (options.forcePropagate) {
        this._availability.next(availability);
      }
      return of(availability);
    }
    // Otherwise, load the time slots from the slots previously loaded
    return this._httpClient.post<any>(
      `${environment.qart.apiUrl}/${environment.qart.apiVersion}/merchants/${this._merchant._id}/calendars/availability`,
      {
        employeeIds: employeeIds.join(),
        startDate,
        endDate,
        duration
      },
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        withCredentials: true
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error(`Error: ${error}`);
          return of([]);
        }),
        // Handle the logic of the cache and the propagation
        tap((availability: any) => {
          if (options.cache) {
            this._cacheService.set(this._cacheNamespace, 'availability', cacheParams, availability, options.expire);
          }
          if (options.propagate) {
            this._availability.next(availability);
          }
        })
      );
  }

  /**
   * Retrieves the calendar events for a given employee within a specified date range.
   * @param employee - The employee for whom to retrieve calendar events.
   * @param startDate - The start date of the date range.
   * @param endDate - The end date of the date range.
   * @param options - The options related to cache and propagation.
   * @returns A list of calendar events.
   */
  getCalendarEventsFromEmployee(
    employee: Employee,
    startDate: Date,
    endDate: Date,
    options: HttpOptions=DEFAULT_HTTP_OPTIONS_5_MINUTES
  ): Observable<any> {
    if (!this._merchant) {
      console.log('Error: No merchant.');
      return of([]);
    }
    // If the events are already loaded, return them
    const cacheParams: any = { 
      merchantId: this._merchant._id,
      employee,
      startDate,
      endDate
    };
    if (options.cache && this._cacheService.has(this._cacheNamespace, 'events', cacheParams)) {
      const events: any = this._cacheService.get(this._cacheNamespace, 'events', cacheParams);
      if (options.forcePropagate) {
        this._events.next(events);
      }
      return of(events);
    }
    // Otherwise, load the events from the events previously loaded
    return this._httpClient.post<any>(
      `${environment.qart.apiUrl}/${environment.qart.apiVersion}/merchants/${this._merchant._id}/calendars/employee/${employee._id}`,
      { startDate, endDate },
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        withCredentials: true
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error(`Error: ${error}`);
          return of([]);
        }),
        // Handle the logic of the cache and the propagation
        tap((events: any) => {
          if (options.cache) {
            this._cacheService.set(this._cacheNamespace, 'events', cacheParams, events, options.expire);
          }
          if (options.propagate) {
            this._events.next(events);
          }
        })
      );
  }

  /**
   * Adds an event to an employee's calendar.
   * @param serviceId The ID of the service being provided.
   * @param employeeId The ID of the employee who will be providing the service.
   * @param startDate The start date and time of the event.
   * @param duration The duration of the event in minutes.
   * @param customerName The name of the customer who will be receiving the service.
   * @param customerEmail The email address of the customer who will be receiving the service.
   * @returns The response from the server.
   */
  addEventToEmployeeCalendar(
    serviceId: string,
    employeeId: string,
    startDate: moment.Moment,
    duration: number,
    customerName: string,
    customerEmail: string
  ): Observable<any> {
    if (!this._merchant) {
      console.log('Error: No merchant.');
      return of(null);
    }
    return this._httpClient.post<any>(
      `${environment.qart.apiUrl}/${environment.qart.apiVersion}/merchants/${this._merchant._id}/calendars/event`,
      {
        merchantId: this._merchant._id,
        serviceId,
        employeeId,
        startDate,
        duration,
        customerName,
        customerEmail
      },
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        withCredentials: true
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error(`Error: ${error}`);
          return of(null);
        }),
        // Handle the logic of the cache and the propagation
        tap(() => {
          this._cacheService.delete(this._cacheNamespace, 'availability', null);
          this._cacheService.delete(this._cacheNamespace, 'events', null);
        })
      );
  }

  /**
   * Retrieves a single event from the specified calendar.
   * @param calendarId The ID of the calendar to retrieve the event from.
   * @param eventId The ID of the event to retrieve.
   * @param options - The options related to cache and propagation.
   * @returns The requested event.
   */
  getEvent(calendarId: string, eventId: string, options: HttpOptions=DEFAULT_HTTP_OPTIONS_5_MINUTES): Observable<any> {
    if (!this._merchant) {
      console.log('Error: No merchant.');
      return of(null);
    }
    // If the event is already loaded, return it
    const cacheParams: any = { 
      merchantId: this._merchant._id,
      calendarId,
      eventId
    };
    if (options.cache && this._cacheService.has(this._cacheNamespace, 'event', cacheParams)) {
      const event: any = this._cacheService.get(this._cacheNamespace, 'event', cacheParams);
      return of(event);
    }
    // Otherwise, load the events from the events previously loaded
    return this._httpClient.get<any>(
      `${environment.qart.apiUrl}/${environment.qart.apiVersion}/merchants/${this._merchant._id}/calendars/${calendarId}/event/${eventId}`,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        withCredentials: true
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error(`Error: ${error}`);
          return of(null);
        }),
        // Handle the logic of the cache and the propagation
        tap((event: any) => {
          if (options.cache) {
            this._cacheService.set(this._cacheNamespace, 'event', cacheParams, event, options.expire);
          }
        })
      );
  }

  /**
   * Moves an event from one calendar to another.
   * @param calendarId The ID of the calendar containing the event to be moved.
   * @param eventId The ID of the event to be moved.
   * @param newCalendarId The ID of the calendar to move the event to.
   * @param startDate The new start date of the event.
   * @param endDate The new end date of the event.
   * @param options - The options related to cache and propagation.
   * @returns The updated Order object.
   */
  moveEvent(
    calendarId: string,
    eventId: string,
    newCalendarId: string,
    startDate: moment.Moment,
    endDate: moment.Moment,
    options: HttpOptions=DEFAULT_HTTP_OPTIONS_5_MINUTES
  ): Observable<Order> {
    if (!this._merchant) {
      console.log('Error: No merchant.');
      return of(null);
    }
    return this._httpClient.post<Order>(
      `${environment.qart.apiUrl}/${environment.qart.apiVersion}/merchants/${this._merchant._id}/calendars/${calendarId}/event/${eventId}/move`,
      {
        newCalendarId,
        startDate,
        endDate
      },
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        withCredentials: true
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error(`Error: ${error}`);
          return of(null);
        }),
        // Handle the logic of the cache and the propagation
        tap((updatedOrder: Order) => {
          if (options.cache) {
            const cacheParams: any = { 
              merchantId: this._merchant._id,
              calendarId,
              eventId
            };
            this._cacheService.delete(this._cacheNamespace, 'order', { merchantId: updatedOrder.merchantId, orderId: updatedOrder._id});
            this._cacheService.delete(this._cacheNamespace, 'event', cacheParams);
            this._cacheService.delete(this._cacheNamespace, 'events', null);
          }
          this._orderService.getOrder(updatedOrder._id, DEFAULT_HTTP_OPTIONS_5_MINUTES).subscribe();
        })
      );
  }

  /**
   * Deletes an event from the calendar.
   * @param calendarId The ID of the calendar to delete the event from.
   * @param eventId The ID of the event to delete.
   * @param options - The options related to cache and propagation.
   * @returns The updated order.
   */
  deleteEvent(calendarId: string, eventId: string, options: HttpOptions=DEFAULT_HTTP_OPTIONS_5_MINUTES): Observable<Order> {
    if (!this._merchant) {
      console.log('Error: No merchant.');
      return of(null);
    }
    return this._httpClient.delete<Order>(
      `${environment.qart.apiUrl}/${environment.qart.apiVersion}/merchants/${this._merchant._id}/calendars/${calendarId}/event/${eventId}`,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        withCredentials: true
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error(`Error: ${error}`);
          return of(null);
        }),
        // Handle the logic of the cache and the propagation
        tap((response: any) => {
          if (!response || !('updatedOrder' in response)) {
            return null;
          }
          const order: Order = response['updatedOrder'];
          if (options.cache) {
            const cacheParams: any = { 
              merchantId: this._merchant._id,
              calendarId,
              eventId
            };
            this._cacheService.delete(this._cacheNamespace, 'order', { merchantId: order.merchantId, orderId: order._id});
            this._cacheService.delete(this._cacheNamespace, 'event', cacheParams);
            this._cacheService.delete(this._cacheNamespace, 'events', null);
          }
          
          this._orderService.getOrder(order._id, DEFAULT_HTTP_OPTIONS_5_MINUTES).subscribe();
        })
      );
  }

}
